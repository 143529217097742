.App-logo {
  height: 40vmin;
  pointer-events: none;
}

:root {
  --color-1: #1A237E;
  --color-2: #B71C1C;
  --color-3: #33691E;
  --color-4: #1A237E;
  --color-5: #1A237E;
}

.mixBackground {
  background-image: linear-gradient(45deg,#F3E5F5, #212121,#CE93D8)
}
.menuBackground {
  background-image: linear-gradient(90deg,#F3E5F5, #212121,#CE93D8);
  color: white;
  width: fit-content;
  height: fit-content;
  margin: 0px;
  padding: 0px;
}
.App{
  max-width: 100vw;
  overflow-x: clip;
  min-width: 390px;
}

body{
  min-width: 390px;
  max-width: 100vw;
  overflow-y: scroll;
}

.headerMargin{
   height: 100px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.selfCenter{
  align-self: center;
}




.section-head{
  color:#9c27b0;
  text-underline-offset: 5px;
  font-weight:400;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  margin:"16px";
  background: linear-gradient(
    220deg, var(--color-1) 19%,
    transparent 19%, transparent 20%,
    var(--color-2) 20%, var(--color-2) 39%,
    transparent 39%, transparent 40%,
    var(--color-3) 40%, var(--color-3) 59%,
    transparent 59%,transparent 60%,
    var(--color-4) 60%, var(--color-4) 79%,
    transparent 79%, transparent 80%, 
    var(--color-5) 80%
  );
  -webkit-background-clip: text;
  color: transparent;
  width:fit-content;
  border-bottom: 1px solid #9c27b0;
  border-right: 5px solid #9c27b0;
  border-left: 2.5px solid #9c27b0;
  border-top: 1px solid #9c27b0;
  
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.leftText{
  text-align: left;
}
.rightText{
  text-align: right;
}
.centerAuto {
  margin-left: auto; 
  margin-right: auto; 
}

.mx-1{
  margin-right: 8px;
  margin-left: 8px;
}
.mx-2{
  margin-right: 16px;
  margin-left: 16px;
}
.mx-3{
  margin-right: 24px;
  margin-left: 24px;
}
.mx-4{
  margin-right: 32px;
  margin-left: 32px;
}
.my-1{
  margin-top: 8px;
  margin-bottom: 8px;
}
.my-2{
  margin-top: 16px;
  margin-bottom: 16px;
}
.my-3{
  margin-top: 24px;
  margin-bottom: 24px;
}
.my-4{
  margin-top: 32px;
  margin-bottom: 32px;
}
.centreFlex{
  display: flex;
  justify-content: center;
  align-items: center;
}
.border{
  border:.1px solid lightgray;
  padding: 32px;
}
.py-0 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.px-0 {
  padding-left: 4px;
  padding-right: 4px;
}
.py-4{
  padding-top: 32px;
  padding-bottom: 32px;
}
.py-2{
  padding-top: 16px;
  padding-bottom: 16px;
}
.p-1{
  padding: 8px;
}
.p-2{
  padding: 16px;
}
.p-3{
  padding: 24px;
}
.p-4{
  padding: 32px;
}
.fCol{
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.fRow{
  display: flex;
  flex-direction: row;
  column-gap: 16px;
}

.fCenter{
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.fLeft{
  justify-content: flex-start;
  align-items: flex-start;
}
.fWrap{
  flex-wrap: wrap;
}
.fullWidth{
  width: 100%;
}
.fRight{
  justify-content:flex-end;
  align-items: flex-end;
}

.right{
  float: right;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #FFFFFF inset;
}

.errorMsg{
  color: #d32f2f;
  font-size: 0.75 rem;
}

.menuIcon{
   background-color: "9c27b0";
   color: white;
   margin-right: 8px;
}

.link{
  text-decoration: none;
  font-size: small;
  margin-top: -3px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #FAFAFA; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #EF6C00; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #EF6C00; 
}

.grid-container{
  display:grid;
  position:relative;
  bottom: 0%;
  width: 100%;
  grid-template-columns: auto;
  background-color:black;
  grid-gap: .1vw 0vw;
  z-index: -1;
  font-size: 14px;
}

.grid-ele{
  color: white;
  margin: 0px 0px;

}
.font-20{
 font-size: 20px;
}

.prodImage {
  height:510px
}
.otpInput{
  height: 45px;
  width: 70px;
  text-align: center;
}

@media screen and (max-width: 800px) {
    .prodImage{
        height: 400px;
      }
  }
@media screen and (max-width: 1300px ) and (min-width: 801px) {
    .prodImage{
        height: 510px;
      }
  }

