.caurousel-img{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: aliceblue;
}

.img-cover{
    width: 100%;
}

.thumb {
  height: 50px;
  object-fit: cover;
  object-position: center;
  background-color: black;
}


@media screen and (max-width: 800px) {
    .caurousel-img{
        height: 220px;
        background-color: antiquewhite;
      }
  }
@media screen and (max-width: 1300px ) and (min-width: 801px) {
    .caurousel-img{
        height: 300px;
        background-color: antiquewhite;
      }
  }